import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, deleteUser } from 'firebase/auth';
import { uploadBytes, getStorage, ref, getDownloadURL } from "firebase/storage";
import axios from 'axios';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain:  process.env.REACT_APP_AUTHDOMAIN,
    projectId:  process.env.REACT_APP_PROJECTID,
    storageBucket:  process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId:  process.env.REACT_APP_MESSAGINGSENDERID,
    appId:  process.env.REACT_APP_APPID,
    measurementId:  process.env.REACT_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app)

export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  'hd': 'liquidos.cl'
});

export const signInWithGoogle = async() => {
  signInWithPopup(auth, provider).then((result) => {
    console.log(result);
    const handleVerifyToken = async (result) => {
      const idToken = result.user.accessToken;
    
      try {
        const response = await axios.post('/api/verifyToken', { "idToken": idToken }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    handleVerifyToken(result);
    console.log(auth.currentUser);
    if (result.user.email.split("@")[1] !== "liquidos.cl") {
      deleteUser(auth.currentUser).then(() => {
        console.log("borrar");
        signOutGoogle();
      }).catch((error) => {
        console.log(error);
        signOutGoogle();
      });
    }
  }).catch((error) => {
    console.log(error);
  });
};

export const signOutGoogle = () => {
  signOut(auth).then(() => {
    console.log("signed out")
  }).catch((error) => {
    console.log(error)
  });
};

export function uploadImage(file, checklistId, answerId, itemId, index) {
  const storageRef = ref(
    storage,
    `checklistImages/${checklistId}/${answerId}/${itemId}/${index}`,
  )

  return uploadBytes(storageRef, file)
    .then((snapshot) => {
      return getDownloadURL(snapshot.ref)
    })

    .catch((error) => {
      console.error(error)

      throw error
    })
};