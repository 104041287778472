import { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ChipsArray from '../utils/ChipsArray';
import MenuItem from '@mui/material/MenuItem';

import ItemsModalQuestion from './ItemsModalQuestion';

const ItemsModal = ({ user, items, openModal, handleClose, type, position, areaId, updateRefresher }) => {
    const [questions, setQuestions] = useState(items[position]?.questions || []);
    const [itemName, setItemName] = useState(items[position]?.name || "")
    const [questionName, setQuestionName] = useState("")
    const [selectType, setSelectType] = useState("question")
    const [chipData, setChipData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true)
        if (type !== "delete") {
            const body = {
                name: itemName,
                userId: user.uid,
                questions: questions 
            }

            if (type === "add") {
                await axios
                  .post(`/api/areas/${areaId}/items`, body, {
                    headers: {
                      "content-type": "application/json",
                    },
                  })
                  .then((response) => {
                    console.log(response)
                    setLoading(false);
                    updateRefresher();
                    closeModal();
                  })
                  .catch((error) => {
                    setLoading(false);
                    console.error(error);
                  });
              } else {
                await axios
                  .put(`/api/areas/${areaId}/items/${items[position].uid}`, body, {
                    headers: {
                      "content-type": "application/json",
                    },
                  })
                  .then((response) => {
                    console.log(response)
                    setLoading(false);
                    updateRefresher();
                    closeModal();
                  })
                  .catch((error) => {
                    setLoading(false);
                    console.error(error);
                  });
              }
        } else {
            await axios
                  .delete(`/api/areas/${areaId}/items/${items[position].uid}`)
                  .then((response) => {
                    console.log(response)
                    setLoading(false);
                    updateRefresher();
                    closeModal();
                  })
                  .catch((error) => {
                    setLoading(false);
                    console.error(error);
                  });
        }
    }

    const closeModal = () => {
        handleClose();
    };

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            console.log('value', e.target.value);
            setChipData(prev => [...prev, e.target.value]);
            e.target.value = "";
        }
    };

    const selectChange = (event) => {
        setSelectType(event.target.value);
    };

    const addQuestion = (e) => {
        e.preventDefault();

        if(["select","multipleSelect"].includes(selectType)){
            setQuestions([...questions, {
                name: questionName,
                type: selectType,
                options: chipData
            }]);
            setChipData([]);
        } else {
            setQuestions([...questions, {
                name: questionName,
                type: selectType
            }]);
        }

        setQuestionName('');
        setSelectType('question');
    }

    return (
        <Modal
            disableScrollLock={true}
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                paddingBottom: 2,
                maxHeight: '95vh',
                overflowY: 'auto'
            }}>
                <Container maxWidth='xl'>
                    {type !== "delete" ?
                        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h5">{type === "add" ? "Nuevo item" : "Editar item"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl required={true} sx={{
                                    marginTop: 2,
                                }}>
                                    <InputLabel htmlFor="name">Item</InputLabel>
                                    <Input id="name" name="name" value={itemName} onChange={(e) => setItemName(e.target.value)}
                                        sx={{ width: '280px' }} />
                                </FormControl>
                            </Grid>
                            {questions.length > 0 ?<Grid item xs={12} sx={{mt: 2}}>
                                <Typography variant="h5" component="h5">Preguntas</Typography>
                            </Grid> : null}
                            {questions.length > 0 ? questions.map((question, i) => (
                                <ItemsModalQuestion key={"imq"+i} questions={questions} setQuestions={setQuestions} position={i}/>
                            )): null}
                            <Grid item xs={12} sx={{mt: 4}}>
                                <Typography variant="h5" component="h5">Nueva pregunta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl required={true} sx={{
                                    marginTop: 2,
                                }}>
                                    <InputLabel htmlFor="name">Pregunta</InputLabel>
                                    <Input id="name" name="name" value={questionName} onChange={(e) => setQuestionName(e.target.value)}
                                        sx={{ width: '280px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl required={true} sx={{
                                    marginTop: 2,
                                }}>
                                    <InputLabel htmlFor="type">Tipo</InputLabel>
                                    <Select
                                        labelId="type"
                                        id="type"
                                        value={selectType}
                                        label="type"
                                        onChange={selectChange}
                                        sx={{ width: '280px' }}
                                    >
                                        <MenuItem value="question">Pregunta abierta</MenuItem>
                                        <MenuItem value="select">Pregunta cerrada</MenuItem>
                                        <MenuItem value="multipleSelect">Opciones múltiples</MenuItem>
                                        <MenuItem value="numeric">Valor numérico</MenuItem>
                                        <MenuItem value="date">Fecha</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {["select","multipleSelect"].includes(selectType) ? <Grid item xs={12}>
                                <FormControl required={true} sx={{
                                    marginTop: 2,
                                }}>
                                    <InputLabel htmlFor="options">Opciones</InputLabel>
                                    <Input id='options' name='options' onKeyDown={keyPress}
                                        sx={{ width: '280px' }} />
                                    {chipData.length > 0 && <ChipsArray chipData={chipData} setChipData={setChipData} />}
                                </FormControl>
                            </Grid>
                                : null}
                            <Grid item xs={12} sx={{ marginTop: '20px' }}>
                                <LoadingButton
                                    size="large"
                                    variant="contained"
                                    onClick={addQuestion}
                                    sx={{ alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none' }}>
                                    Agregar pregunta
                                </LoadingButton>
                            </Grid>
                            {questions.length > 0 ? <Grid item xs={12} sx={{ marginTop: '20px' }}>
                                <LoadingButton
                                    loading={loading}
                                    size="large"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{ alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none' }}>
                                    {type === "add" ? "Crear item" : "Guardar cambios"}
                                </LoadingButton>
                            </Grid> : null}
                        </Grid> :
                        <Grid container columnSpacing={2} rowSpacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">Seguro que quieres eliminar el item {items[position]?.name}?</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    sx={{ alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none', width: '75%' }}
                                    onClick={closeModal}>No</Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    variant="contained"
                                    sx={[
                                        {
                                            alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none',
                                            backgroundColor: 'red', width: '75%'
                                        },
                                        (theme) => ({
                                            '&:hover': {
                                                backgroundColor: '#b42f2f',
                                            },
                                        })]}
                                    onClick={handleSubmit}>Sí</Button>
                            </Grid>
                        </Grid>
                    }
                </Container>
            </Box>
        </Modal>
    );
}

export default ItemsModal;