import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typhography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";

import "../../scss/NewChecklistCard.scss";

const SelectChecklistCard = ({ checklists, position }) => {
  const navigate = useNavigate();

  const fillChecklist = () => {
    navigate(`/fillchecklist/${checklists[position].uid}`);
  };

  return (
    <Card
      elevation={12}
      onClick={fillChecklist}
      sx={[
        (_theme) => ({
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        }),
      ]}
    >
      <CardHeader
        sx={[
          {
            position: "relative",
            p: 1,
            m: 1,
            borderBottom: 1,
            borderColor: "grey.500",
            minHeight: 120,
            flexWrap: "nowrap",
          },
        ]}
        title={
          <Typhography variant="h5" component="h3">
            {checklists[position].name}
          </Typhography>
        }
      ></CardHeader>
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Typhography variant="body" component={"p"}>
              Usuario: {checklists[position].userName}
            </Typhography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typhography variant="body" component={"p"}>
              Items: {checklists[position].items.length}
            </Typhography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typhography variant="body" component={"p"}>
              Creado: {checklists[position].created_at}
            </Typhography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typhography variant="body" component={"p"}>
              Actualizado: {checklists[position].updated_at}
            </Typhography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SelectChecklistCard;
