import { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

const ChecklistDeleteModal = ({ checklists, openModal, handleClose, position, setOpen, setSeverity, setNotificationMessage, setCounter }) => {
    const [buttonLoading, setButtonLoading] = useState(false);

    const closeModal = () => {
        handleClose();
    };

    const deleteChecklist = async (e) => {
        e.preventDefault();
        setButtonLoading(true);

        try {
            const response = await axios.delete(`/api/checklist/${checklists[position].uid}`);
            setButtonLoading(false);
            closeModal();
            setNotificationMessage("Checklist eliminado satisfactiriamente");
            setOpen(true);
            setSeverity('info');
            setTimeout(() => {
                setOpen(false);
            }, 3000);
            console.log(response);
            setCounter(prev => {
                return prev + 1
            });
        } catch (err) {
            console.log(err);
            setButtonLoading(false);
            setNotificationMessage("Error al eliminar checklist");
            setOpen(true);
            setSeverity('error');
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }

    return (
        <Modal
            disableScrollLock={true}
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 420,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                paddingBottom: 2
            }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={2} rowSpacing={4}>
                        <Grid item xs={12} sx={{ display: ' flex', justifyContent: 'center' }}>
                            <Typography variant="h5" component="h5">Estás seguro de que quieres eliminar el checklist {checklists[position]?.name}? </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                sx={{ alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none', width: '75%' }}
                                onClick={closeModal}>Cancelar</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <LoadingButton
                                variant="contained"
                                loading={buttonLoading}
                                sx={[
                                    {
                                        alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none',
                                        backgroundColor: 'red', width: '75%'
                                    },
                                    (theme) => ({
                                        '&:hover': {
                                            backgroundColor: '#b42f2f',
                                        },
                                    })]}
                                onClick={deleteChecklist}>Eliminar</LoadingButton>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Modal>
    );
}

export default ChecklistDeleteModal;
