import './App.css';
import Login from './components/Login';
import { onAuthStateChanged } from "firebase/auth";
import { auth, signOutGoogle } from './utils/firebase-config';
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Main from './components/Main';
import axios from 'axios';

function App() {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const checkUser = async (email) => {
      try {
        const response = await axios.get(`/api/user/${email}`);
        let role = response.data.response.role;
        let uid = response.data.response.uid;
        if (response.data.response.length === 0) {
          return { response: false, role: null, uid: null };
        }
        return { response: true, role: role, uid: uid };
      } catch (err) {
        console.log(err);
        return { response: false, role: null, uid: null };
      }
    }
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        checkUser(firebaseUser.email).then(result => {
          if (result.response === true) {
            firebaseUser.role = result.role;
            firebaseUser.uid = result.uid;
            setUser(firebaseUser);
            setLoaded(true);
          } else {
            console.log("no user");
            setLoaded(true);
            setUser(null);
            signOutGoogle();
          }
        });
        // ...
      } else {
        // User is signed out
        // ...
        setUser(null);
        console.log("no user");
        setLoaded(true);
      }
    });
  }, []);

  return (
    <Container maxWidth={false} className="App">
      {loaded ? (user ? <Main user={user} /> : <Login />) : null}
    </Container>
  );
}

export default App;
