import { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { uploadImage } from "../../utils/firebase-config";

const FillChecklistSaveModal = ({
  checklist,
  openSaveModal,
  handleSaveClose,
  setOpen,
  setSeverity,
  setNotificationMessage,
  user,
  storeId,
  location,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    handleSaveClose();
  };

  const submitChecklist = async (e) => {
    e.preventDefault();
    setButtonLoading(true);

    const data = {
      name: checklist.name,
      items: checklist.items,
      userId: user.uid,
      storeId: storeId,
      emails: checklist.emails,
      location: location,
    };

    try {
      const response = await axios.post(`/api/answers/${checklist.uid}`, data, {
        headers: {
          "content-type": "application/json",
        },
      });

      console.log(response.data);
      const answerId = response.data.response.id;

      for (const item of data.items) {
        if (item.photo) {
          const imgUrls = await Promise.all(
            item.photo.map(async (photo, i) => {
              return await uploadImage(
                photo,
                checklist.uid,
                answerId,
                item.uid,
                i
              );
            })
          );

          console.log(imgUrls); // Esta será una lista de URLs.
        }
      }
      setOpen(true);
      setSeverity("info");
      setNotificationMessage("Checklist enviado exitosamente");
      setTimeout(() => {
        navigate(`/answers/${checklist.uid}/${answerId}`);
      }, 2000); // 2000 milisegundos = 2 segundos
    } catch (err) {
      console.log(err);
      setButtonLoading(false);
      setOpen(true);
      setSeverity("error");
      setNotificationMessage("Ha ocurrido un error");
    }
  };

  return (
    <Modal
      disableScrollLock={true}
      open={openSaveModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 420,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          paddingBottom: 2,
        }}
      >
        <Container maxWidth="xl">
          <Grid container columnSpacing={2} rowSpacing={4}>
            <Grid
              item
              xs={12}
              sx={{ display: " flex", justifyContent: "center" }}
            >
              <Typography variant="h5" component="h5">
                Enviar checklist?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{
                  alignSelf: "flex-end",
                  mt: 0.2,
                  mb: 0,
                  borderRadius: 0,
                  boxShadow: "none",
                  width: "75%",
                }}
                onClick={closeModal}
                disabled={buttonLoading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                variant="contained"
                loading={buttonLoading}
                sx={[
                  {
                    alignSelf: "flex-end",
                    mt: 0.2,
                    mb: 0,
                    borderRadius: 0,
                    boxShadow: "none",
                    backgroundColor: "red",
                    width: "75%",
                  },
                  (_theme) => ({
                    "&:hover": {
                      backgroundColor: "#b42f2f",
                    },
                  }),
                ]}
                onClick={submitChecklist}
              >
                Enviar
              </LoadingButton>
            </Grid>
            {buttonLoading ? <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography>
                Enviando...
              </Typography>
            </Grid> : null}
          </Grid>
        </Container>
      </Box>
    </Modal>
  );
};

export default FillChecklistSaveModal;
