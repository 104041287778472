import { useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typhography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import '../../scss/NewChecklistCard.scss'
import { Select, FormControl, InputLabel, MenuItem, Input } from '@mui/material';

const questionMapper = (question) => {
    switch (question.type) {
        case "question":
            return <>
            <Grid item xs={12}>
                <FormControl required={true} 
                    sx={{
                        marginTop: 2,
                    }}>
                    <InputLabel htmlFor={"type"+question.name}>{question.name}</InputLabel>
                    <Select
                        labelId={"type"+question.name}
                        id={"type"+question.name}
                        label={"type"+question.name}
                        value=""
                        disabled
                        sx={{ width: '280px' }}
                    >
                        <MenuItem value="">Selecciona una pregunta</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            </>
        case "select":
            return <>
            <Grid item xs={12}>
                <FormControl required={true} 
                    sx={{
                        marginTop: 2,
                    }}>
                    <InputLabel htmlFor={"type"+question.name}>{question.name}</InputLabel>
                    <Select
                        labelId={"type"+question.name}
                        id={"type"+question.name}
                        label={"type"+question.name}
                        value=""
                        disabled
                        sx={{ width: '280px' }}
                    >
                        <MenuItem value="">Selecciona una pregunta</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            </>
        case "multipleSelect":
            return <>
            <Grid item xs={12}>
                <FormControl required={true} 
                    sx={{
                        marginTop: 2,
                    }}>
                    <InputLabel htmlFor={"type"+question.name}>{question.name}</InputLabel>
                    <Select
                        labelId={"type"+question.name}
                        id={"type"+question.name}
                        label={"type"+question.name}
                        value=""
                        disabled
                        sx={{ width: '280px' }}
                    >
                        <MenuItem value="">Selecciona una pregunta</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            </>
        case "numeric":
            return <>
            <Grid item xs={12}>
                <FormControl required={true} 
                    sx={{
                        marginTop: 2,
                    }}>
                    <InputLabel htmlFor={"type"+question.name}>{question.name}</InputLabel>
                    <Input
                        labelId={"type"+question.name}
                        id={"type"+question.name}
                        label={"type"+question.name}
                        disabled
                        type="numeric"
                        sx={{ width: '280px' }}
                    />
                </FormControl>
            </Grid></>
        case "date":
            return <>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <InputLabel htmlFor={"type"+question.name}>{question.name}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            labelId={"type"+question.name}
                            disabled 
                            sx={{width: '280px'}} 
                            label="Selecciona una fecha" 
                            format="YYYY/MM/DD" 
                        />
                    </LocalizationProvider>
                </Grid> 
            </Grid></>
        default:
            return null
    }
}

const NewChecklistItemCard = ({ item, handleOpen, position }) => {
    const [openDial, setOpenDial] = useState(false);

    const handleClick = () => { setOpenDial(!openDial); };

    const handleCliCkAway = () => {
        setOpenDial(false);
    }

    const actions = [
        { icon: <IconButton onClick={() => handleOpen("edit", position)}><EditIcon /></IconButton>, name: 'Edit' },
        { icon: <IconButton onClick={() => handleOpen("delete", position)}><DeleteIcon /></IconButton>, name: 'Delete' },
    ];

    return (
        <Card elevation={12}
            sx={[{
                margin: 3,
            }]}>
            <CardHeader
                sx={[{ position: "relative", p: 1, m: 1, borderBottom: 1, borderColor: 'grey.500', minHeight: 120, flexWrap: 'wrap' }]}
                title={<Box><Typhography variant="h5" component="h3" >{position+1}. {item?.name}</Typhography></Box>}
                action={
                    <ClickAwayListener onClickAway={handleCliCkAway}>
                        <SpeedDial
                            ariaLabel="SpeedDial"
                            icon={<SpeedDialIcon />}
                            direction="down"
                            FabProps={{ size: 'medium', border: 'none' }}
                            onClick={handleClick}
                            elevation={0}
                            open={openDial}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={handleClick}
                                    delay={1}
                                />
                            ))}
                        </SpeedDial>
                    </ClickAwayListener>
                }>
            </CardHeader>
            <CardContent>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    {item?.questions?.map(question => questionMapper(question))}
                    <Grid item xs={12}>{item?.photoRequired ? "Foto requerida": "Foto opcional"}</Grid>
                </Grid>
            </CardContent>
        </Card >
    );
};

export default NewChecklistItemCard;