import "../../scss/NewChecklistLayout.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SnackbarAlert from "../utils/SnackbarAlert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import AnswerCard from "./AnswerCard";
import { useParams } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important`,
  },
});

function AnswerLayout({ role, setSelectedIndex }) {
  const [answer, setAnswer] = useState({ name: null, items: [] });
  const [loaded, setLoaded] = useState(false);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [notificationMessage, setNotificationMessage] = useState("");

  const { checklistId, answerId } = useParams();

  useEffect(() => {
    if (role === "admin") {
      setSelectedIndex(4);
    } else {
      setSelectedIndex(2);
    }

    const getAnswer = async () => {
      try {
        const response = await axios.get(
          `/api/answers/${checklistId}/${answerId}`
        );
        console.log(response.data.response);
        setAnswer(response.data.response);
        setLoaded(true);
        return true;
      } catch (err) {
        console.log(err);
        setOpen(true);
        setSeverity("error");
        setNotificationMessage("Error obteniendo respuesta");
        setLoaded(true);
        return false;
      }
    };

    getAnswer();
  }, [checklistId, answerId, role, setSelectedIndex]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {loaded ? (
          <Box>
            <Paper
              sx={{
                p: 1.5,
                mb: 1,
                backgroundColor: "#E6D41E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{ color: "black", ml: 4 }}
              >
                {answer.name}
              </Typography>
            </Paper>
            <Container
              maxWidth="xl"
              sx={{ mt: 2, mb: 4, display: "flex", flexWrap: "wrap" }}
            >
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 3, sm: 6, md: 12 }}
              >
                {answer.items.length > 0
                  ? answer.items.map((item, i) => (
                      <Grid key={i} item xs={12}>
                        <AnswerCard key={"p" + i} item={item} position={i} />
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Container>
          </Box>
        ) : (
          <Box>
            <Typography variant="body">Cargando...</Typography>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <SnackbarAlert
          open={open}
          setOpen={setOpen}
          severity={severity}
          notificationMessage={notificationMessage}
        />
      </Box>
    </ThemeProvider>
  );
}

export default AnswerLayout;
