import { useEffect, useState } from "react";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import Typhography from "@mui/material/Typography";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "../../scss/NewChecklistCard.scss";
import {
  Box,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Modal,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Input,
} from "@mui/material";

import Camera from "../utils/Camera";
import FillChecklistPhotoLayout from "./FillChecklistPhotoLayout";

const FillChecklistItemCard = ({ checklist, setChecklist, item, position }) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("take");
  const [selectedPhotoPosition, setSelectedPhotoPosition] = useState(0);
  const [inputValues, setInputValues] = useState([]);

  useEffect(() => {
    setInputValues(item?.questions?.map((q) => {
      if (q.answer) {
        return q.answer
      } else {
        return ''
      }
    }))
  }, [item]);

  const handleClose = () => setOpen(false);
  const handleOpen = (index) => {
    setOpen(true);
    setSelectedPhotoPosition(index);
  };

  const handleInputChange = (itemIndex, questionIndex, newValue) => {
    const updatedChecklist = { ...checklist };
    updatedChecklist.items[itemIndex].questions[questionIndex].answer =
      newValue;
    setChecklist(updatedChecklist);
  };

  const deletePhoto = () => {
    const updatedChecklist = { ...checklist };
    if (updatedChecklist.items[position].photo.length === 1) {
      delete updatedChecklist.items[position].photo;
    } else {
      updatedChecklist.items[position].photo.splice(0, 1);
    }
    setChecklist(updatedChecklist);
  };

  const handleQuestionChange = (position, newValue) => {
    const updatedValues = [...inputValues];

    updatedValues[position] = newValue;

    setInputValues(updatedValues);
  };

  const questionMapper = (question, itemIndex, questionIndex) => {
    switch (question.type) {
      case "question":
        return (
          <Grid item xs={12}>
            <FormControl sx={{ marginTop: 2, minWidth: '80%' }}>
              <InputLabel htmlFor={"type" + question.name} shrink={Boolean(inputValues[questionIndex])}>
                {question.name}
              </InputLabel>
              <Input
                id={"type" + question.name}
                label={"type" + question.name}
                value={inputValues[questionIndex]}
                onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
                onBlur={(e) =>
                  handleInputChange(itemIndex, questionIndex, e.target.value)
                }
              />
            </FormControl>
          </Grid>
        );
      case "select":
        return (
          <Grid item xs={12}>
            <FormControl required={true} sx={{ marginTop: 2, minWidth: '80%' }}>
              <InputLabel htmlFor={"type" + question.name}>
                {question.name}
              </InputLabel>
              <Select
                labelId={"type" + question.name}
                id={"type" + question.name}
                label={"type" + question.name}
                value={question.answer}
                multiple={question.type === "multipleSelect"}
                onChange={(e) =>
                  handleInputChange(itemIndex, questionIndex, e.target.value)
                }
                required
              >
                {question.options.map((option, idx) => (
                  <MenuItem key={idx} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      case "multipleSelect":
        return (
          <Grid item xs={12}>
            <FormControl required={true} sx={{ marginTop: 2, minWidth: '80%' }}>
              <InputLabel htmlFor={"type" + question.name}>
                {question.name}
              </InputLabel>
              <Select
                labelId={"type" + question.name}
                id={"type" + question.name}
                label={"type" + question.name}
                value={question.answer}
                multiple={question.type === "multipleSelect"}
                onChange={(e) =>
                  handleInputChange(itemIndex, questionIndex, e.target.value)
                }
                required
              >
                {question.options.map((option, idx) => (
                  <MenuItem key={idx} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      case "numeric":
        return (
          <Grid item xs={12}>
            <FormControl required={true} sx={{ marginTop: 2, minWidth: '80%' }}>
              <InputLabel htmlFor={"type" + question.name}>
                {question.name}
              </InputLabel>
              <Input
                id={"type" + question.name}
                label={"type" + question.name}
                type="number"
                inputProps={{ min: "0", step: "1", pattern: "\\d*" }}
                value={question.answer}
                onChange={(e) =>
                  handleInputChange(itemIndex, questionIndex, e.target.value)
                }
                required
              />
            </FormControl>
          </Grid>
        );
      case "date":
        return (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <InputLabel htmlFor={"type" + question.name} sx={{ mb: 1 }}>
                {question.name}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Selecciona una fecha"
                  format="YYYY/MM/DD"
                  value={dayjs(question.answer)}
                  onChange={(date) =>
                    handleInputChange(
                      itemIndex,
                      questionIndex,
                      date.format("YYYY-MM-DD")
                    )
                  }
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Card elevation={12}>
      <CardHeader
        sx={{
          position: "relative",
          p: 1,
          m: 1,
          borderBottom: 1,
          borderColor: "grey.500",
          minHeight: 120,
          flexWrap: "wrap",
        }}
        title={
          <Box>
            <Typhography variant="h5" component="h3">
              {position + 1}. {item?.name}
            </Typhography>
          </Box>
        }
      />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {item?.questions?.map((question, idx) =>
            questionMapper(question, position, idx)
          )}
          <Grid item xs={12}>
            {item?.photoRequired ? "Foto requerida" : "Foto opcional"}
          </Grid>
          {item?.photo ? (
            <>
              {item.photo.map((_photo, i) => (
                <FillChecklistPhotoLayout
                  item={item}
                  setType={setType}
                  handleOpen={handleOpen}
                  position={position}
                  photoPosition={i}
                  setChecklist={setChecklist}
                  checklist={checklist}
                />
              ))}
              <FillChecklistPhotoLayout
                item={item}
                setType={setType}
                handleOpen={handleOpen}
                position={position}
                photoPosition={item.photo.length}
                setChecklist={setChecklist}
                checklist={checklist}
              />
            </>
          ) : (
            <FillChecklistPhotoLayout
              item={item}
              setType={setType}
              handleOpen={handleOpen}
              position={position}
              photoPosition={0}
              setChecklist={setChecklist}
              checklist={checklist}
            />
          )}
        </Grid>
        {open ? <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "0px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              maxWidth: "800px",
              maxHeight: "95%",
            }}
          >
            <Camera
              checklist={checklist}
              setChecklist={setChecklist}
              position={position}
              type={type}
              setType={setType}
              handleClose={handleClose}
              selectedPhotoPosition={selectedPhotoPosition}
            />
          </div>
        </Modal> : null}
      </CardContent>
    </Card>
  );
};

export default FillChecklistItemCard;
