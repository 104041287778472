import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typhography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "../../scss/NewChecklistCard.scss";
import {
  Select,
  InputLabel,
  MenuItem,
  Input,
} from "@mui/material";

const questionMapper = (question) => {
  switch (question.type) {
    case "question":
      return (
        <>
          <Grid item xs={12}>
            <InputLabel htmlFor={"type" + question.name}>
              {question.name}
            </InputLabel>
            <Input
              labelId={"type" + question.name}
              id={"type" + question.name}
              label={"type" + question.name}
              disabled
              sx={{ width: "280px" }}
              value={question.answer}
            />
          </Grid>
        </>
      );
    case "select":
      return (
        <>
          <Grid item xs={12}>
            <InputLabel htmlFor={"type" + question.name}>
              {question.name}
            </InputLabel>
            <Select
              labelId={"type" + question.name}
              id={"type" + question.name}
              label={"type" + question.name}
              value={question.answer}
              disabled
              sx={{ width: "280px" }}
            >
              <MenuItem value={question.answer}>{question.answer}</MenuItem>
            </Select>
          </Grid>
        </>
      );
    case "multipleSelect":
      return (
        <>
          <Grid item xs={12}>
            <InputLabel htmlFor={"type" + question.name}>
              {question.name}
            </InputLabel>
            <Select
              labelId={"type" + question.name}
              id={"type" + question.name}
              label={"type" + question.name}
              value={question.answer}
              disabled
              sx={{ width: "280px" }}
            >
              <MenuItem value={question.answer}>{`${question.answer.join(', ')}`}</MenuItem>
            </Select>
          </Grid>
        </>
      );
    case "numeric":
      return (
        <>
          <Grid item xs={12}>
            <InputLabel htmlFor={"type" + question.name}>
              {question.name}
            </InputLabel>
            <Input
              labelId={"type" + question.name}
              id={"type" + question.name}
              label={"type" + question.name}
              disabled
              type="numeric"
              sx={{ width: "280px" }}
              value={question.answer}
            />
          </Grid>
        </>
      );
    case "date":
      return (
        <>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <InputLabel htmlFor={"type" + question.name}>
                {question.name}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  labelId={"type" + question.name}
                  disabled
                  sx={{ width: "280px" }}
                  format="YYYY/MM/DD"
                  value={dayjs(question.answer)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};

const AnswerCard = ({ item, position }) => {
  return (
    <Card
      elevation={12}
      sx={[
        {
          margin: 3,
        },
      ]}
    >
      <CardHeader
        sx={[
          {
            position: "relative",
            p: 1,
            m: 1,
            borderBottom: 1,
            borderColor: "grey.500",
            minHeight: 120,
            flexWrap: "wrap",
          },
        ]}
        title={
          <Box>
            <Typhography variant="h5" component="h3">
              {position + 1}. {item?.name}
            </Typhography>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {item?.questions?.map((question) => questionMapper(question))}
          <Grid item xs={12}>
            {item?.photoRequired ? "Foto requerida" : "Foto opcional"}
          </Grid>
            {item?.photo ? item.photo.map(img => <Grid item xs={12}><img src={img} alt="Snapshot" style={{width: '90%', height: '90%'}}/></Grid>) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AnswerCard;
