import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArray({ chipData, setChipData }) {
    const handleDelete = (position) => () => {
      setChipData((chips) => chips.filter((chip, i) => position!==i));
    };
  
    return (
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {chipData?.map((data, i) => {
          let icon;
  
          return (
            <ListItem key={"chip"+i}>
              <Chip
                icon={icon}
                label={data}
                onDelete={handleDelete(i)}
              />
            </ListItem>
          );
        })}
      </Paper>
    );
  }