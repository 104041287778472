import { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

const StoresModal = ({
    user,
    stores,
    openModal,
    handleClose,
    type,
    position,
    setReloadStores,
    setOpen,
    setSeverity,
    setNotificationMessage,
}) => {
    const [loading, setLoading] = useState(false);
    const [formRole, setFormRole] = useState('user')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (type !== "delete") {
            const formEl = e.target.closest('form');
            const formData = new FormData(formEl);
            const entries = {};
            for (let [key, value] of formData.entries()) entries[key] = value.trim();
            entries.supervisorId = user.uid

            if (type === "add") {
                try {
                    const response = await axios.post(`/api/store`, entries)
                    if (response.data.response.status_response === "NOK") {
                        setLoading(false)
                        setOpen(true)
                        setSeverity("error")
                        setNotificationMessage("Error creating store, please try again")
                    } else {
                        setReloadStores(prev => {
                            return prev + 1
                        })
                        setLoading(false)
                        closeModal()
                    }
                } catch (err) {
                    console.log(err)
                    setLoading(false)
                    setOpen(true)
                    setSeverity("error")
                    setNotificationMessage("Error creating store, please try again")
                }
            } 
        } else {
            try {
                const response = await axios.delete(`/api/store/${stores[position].uid}`)
                if (response.data.response.status_response === "NOK") {
                    setLoading(false)
                    setOpen(true)
                    setSeverity("error")
                    setNotificationMessage("Error deleting store, please try again")
                } else {
                    setReloadStores(prev => {
                        return prev + 1
                    })
                    setLoading(false)
                    closeModal()
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
                setOpen(true)
                setSeverity("error")
                setNotificationMessage("Error deleting store, please try again")
            }
        }
    }

    const closeModal = () => {
        handleClose();
    };

    return (
        <Modal
            disableScrollLock={true}
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                pt: 4,
                pb: 4,
                pl: 2,
                pr: 2,
                paddingBottom: 2,
                maxHeight: 720,
                overflowY: "auto",
            }}>
                <Container maxWidth='xl'>
                    {type !== "delete" ?
                        <Grid
                            component="form"
                            onSubmit={handleSubmit}
                            container
                            spacing={2}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">{type === "add" ? "New Store" : "Edit Store"}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ width: "100%" }}>
                                <FormControl
                                    required={true}
                                    sx={{
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <InputLabel htmlFor="name">Name</InputLabel>
                                    <Input
                                        id="name"
                                        name="name"
                                        defaultValue={stores[position]?.name}
                                        sx={{ width: "100%" }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ width: '100%' }}>
                                <FormControl
                                    required={true}
                                    sx={{
                                        marginTop: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <InputLabel htmlFor="email">Email</InputLabel>
                                    <Input
                                        disabled={type === "add" ? false : true}
                                        id="email"
                                        name="email"
                                        type="email"
                                        defaultValue={stores[position]?.email}
                                        sx={{ width: "100%" }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    loading={loading}
                                    loadingIndicator="Saving..."
                                    variant="contained"
                                    type="submit"
                                >
                                    {type === "add" ? "Create" : "Update"}
                                </LoadingButton>
                            </Grid>
                        </Grid> :
                        <Grid container columnSpacing={2} rowSpacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">Are you sure you want to delete store {stores[position]?.name}?</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    sx={{ alignSelf: "flex-end", width: '75%' }}
                                    onClick={closeModal}>No</Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <LoadingButton
                                    variant="contained"
                                    sx={[
                                        {
                                            width: '75%'
                                        },
                                        (theme) => ({
                                            '&:hover': {
                                                backgroundColor: '#b42f2f',
                                            },
                                        })]}
                                    color="error"
                                    loading={loading}
                                    loadingIndicator="Deleting..."
                                    onClick={handleSubmit}>Yes</LoadingButton>
                            </Grid>
                        </Grid>
                    }
                </Container>
            </Box>
        </Modal >
    );
}

export default StoresModal;