import { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const AreasModal = ({
  user,
  areas,
  openModal,
  handleClose,
  type,
  position,
  updateRefresher,
}) => {
  const [name, setName] = useState(areas[position]?.name || "");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (type !== "delete") {
      const body = {
        name: name,
        userId: user.uid,
      };

      if (type === "add") {
        await axios
          .post(`/api/areas/`, body, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((response) => {
            setLoading(false);
            updateRefresher();
            closeModal();
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      } else {
        await axios
          .put(`/api/areas/${areas[position].uid}`, body, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then((response) => {
            setLoading(false);
            updateRefresher();
            closeModal();
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }
    } else {
      await axios
          .delete(`/api/areas/${areas[position].uid}`)
          .then((response) => {
            setLoading(false);
            updateRefresher();
            closeModal();
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
    }
  };

  const closeModal = () => {
    handleClose();
  };

  return (
    <Modal
      disableScrollLock={true}
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          paddingBottom: 2,
        }}
      >
        <Container maxWidth="xl">
          {type !== "delete" ? (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  {type === "add" ? "Nueva área" : "Editar área"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required={true}
                  sx={{
                    marginTop: 2,
                  }}
                >
                  <InputLabel htmlFor="name">Nombre</InputLabel>
                  <Input
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ width: "280px" }}
                  />
                  <FormHelperText>
                    Ejemplo: Locales, Inventarios, Operaciones, etc.
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "20px" }}>
                <LoadingButton
                  size="large"
                  variant="contained"
                  loading={loading}
                  onClick={handleSubmit}
                  sx={{
                    alignSelf: "flex-end",
                    mt: 0.2,
                    mb: 0,
                    borderRadius: 0,
                    boxShadow: "none",
                  }}
                >
                  {type === "add" ? "Agregar area" : "Editar area"}
                </LoadingButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  Seguro que quieres eliminar el área {areas[position]?.name}?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{
                    alignSelf: "flex-end",
                    mt: 0.2,
                    mb: 0,
                    borderRadius: 0,
                    boxShadow: "none",
                    width: "75%",
                  }}
                  onClick={closeModal}
                >
                  No
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  sx={[
                    {
                      alignSelf: "flex-end",
                      mt: 0.2,
                      mb: 0,
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: "red",
                      width: "75%",
                    },
                    (theme) => ({
                      "&:hover": {
                        backgroundColor: "#b42f2f",
                      },
                    }),
                  ]}
                  onClick={handleSubmit}
                >
                  Sí
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </Modal>
  );
};

export default AreasModal;
