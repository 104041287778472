import '../app.scss';
import { signInWithGoogle } from '../utils/firebase-config';

const Login = () => {
    return (
        <div>
            <button type="button" class="login-with-google-btn" onClick={signInWithGoogle}>
                Sign in with Google
            </button>
        </div>
    );
}

export default Login;