import { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { useParams } from 'react-router-dom';
import ChipsArray from '../utils/ChipsArray';

const EditChecklistSaveModal = ({ checklist, openSaveModal, handleSaveClose, setOpen, setSeverity, setNotificationMessage, user }) => {
    const [checklistName, setChecklistName] = useState(checklist.name)
    const [emails, setEmails] = useState(checklist.emails || []);
    const [buttonLoading, setButtonLoading] = useState(false);

    const { checklistId } = useParams();

    const closeModal = () => {
        handleSaveClose();
    };

    const submitChecklist = async (e) => {
        e.preventDefault();
        setButtonLoading(true);

        const data = {
            name: checklistName,
            items: checklist.items,
            emails: emails,
            userId: user.uid
        }

        try {
            await axios.put(`/api/checklist/${checklistId}`, data,
                {
                    headers: {
                        "content-type": "application/json"
                    }
                });
            setButtonLoading(false);
            closeModal();
            setNotificationMessage("Checklist editado satisfactoriamente");
            setOpen(true);
            setSeverity('info');
            setTimeout(() => {
                setOpen(false);
            }, 3000);
            // navigate(`/checklist/${response.data.response.id}`)
        } catch (err) {
            console.log(err);
            setButtonLoading(false);
            setNotificationMessage("Error al editar checklist");
            setOpen(true);
            setSeverity('error');
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            // console.log('value', e.target.value);
            setEmails(prev => [...prev, e.target.value]);
            e.target.value = "";
        }
    };

    return (
        <Modal
            disableScrollLock={true}
            open={openSaveModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 420,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                paddingBottom: 2
            }}>
                <Container maxWidth='xl'>
                    <Grid container columnSpacing={2} rowSpacing={4}>
                        <Grid item xs={12} sx={{ display: ' flex', justifyContent: 'center' }}>
                            <Typography variant="h5" component="h5">Dale un nombre al checklist </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: ' flex', justifyContent: 'center' }}>
                            <TextField sx={{ width: '100%' }} value={checklistName} onChange={(e) => setChecklistName(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="options">Emails a notificar</InputLabel>
                            <Input id='options' name='options' onKeyDown={keyPress}
                                sx={{ width: '280px' }} />
                            {emails.length > 0 && <ChipsArray chipData={emails} setChipData={setEmails} />}
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                sx={{ alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none', width: '75%' }}
                                onClick={closeModal}>Cancelar</Button>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <LoadingButton
                                variant="contained"
                                loading={buttonLoading}
                                sx={[
                                    {
                                        alignSelf: "flex-end", mt: 0.2, mb: 0, borderRadius: 0, boxShadow: 'none',
                                        backgroundColor: 'red', width: '75%'
                                    },
                                    (_theme) => ({
                                        '&:hover': {
                                            backgroundColor: '#b42f2f',
                                        },
                                    })]}
                                onClick={submitChecklist}>Guardar</LoadingButton>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Modal>
    );
}

export default EditChecklistSaveModal;
