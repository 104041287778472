import { useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typhography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';

import '../../scss/NewChecklistCard.scss'

const ChecklistCard = ({ checklists, position, handleOpen }) => {
    const [openDial, setOpenDial] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => { setOpenDial(!openDial); };

    const handleCliCkAway = () => {
        setOpenDial(false);
    }

    const editChecklist = () => {
        navigate(`/checklist/${checklists[position].uid}`)
    }

    const actions = [
        { icon: <IconButton onClick={editChecklist}><EditIcon /></IconButton>, name: 'Edit' },
        { icon: <IconButton onClick={() => handleOpen(position)}><DeleteIcon /></IconButton>, name: 'Delete' },
    ];

    return (
        <Card elevation={12}>
            <CardHeader
                sx={[{ position: "relative", p: 1, m: 1, borderBottom: 1, borderColor: 'grey.500', minHeight: 120, flexWrap: 'nowrap' }]}
                title={<Box><Typhography variant="h5" component="h3" >{checklists[position].name}</Typhography></Box>}
                action={
                    <ClickAwayListener onClickAway={handleCliCkAway}>
                        <SpeedDial
                            ariaLabel="SpeedDial"
                            icon={<SpeedDialIcon />}
                            direction="down"
                            FabProps={{ size: 'medium', border: 'none' }}
                            onClick={handleClick}
                            elevation={0}
                            open={openDial}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={handleClick}
                                    delay={1}
                                />
                            ))}
                        </SpeedDial>
                    </ClickAwayListener>
                }>
            </CardHeader>
            <CardContent>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typhography variant='body' component={'p'}>Usuario: {checklists[position].userName}</Typhography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typhography variant='body' component={'p'}>Items: {checklists[position].items.length}</Typhography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typhography variant='body' component={'p'}>Creado: {checklists[position].created_at}</Typhography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typhography variant='body' component={'p'}>Actualizado: {checklists[position].updated_at}</Typhography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    );
};

export default ChecklistCard;