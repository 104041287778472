import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ChipsArray from '../utils/ChipsArray';
import MenuItem from '@mui/material/MenuItem';

const ItemsModalQuestion = ({questions, setQuestions, position}) => {
    const [chipData, setChipData] = useState(questions[position].options||[]);

    console.log(questions[position])

    const changeName = (event) => {
        const newQuestions = [...questions];
        newQuestions[position] = {
            ...newQuestions[position],
            name: event.target.value
        }
        setQuestions(newQuestions);
    }

    const changeType = (event) => {
        const newQuestions = [...questions];
        newQuestions[position] = {
            ...newQuestions[position],
            type: event.target.value
        }
        setQuestions(newQuestions);
    }

    const deleteQuestion = (e) => {
        e.preventDefault();
        setQuestions(questions.filter((_, i) => i !== position));
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            console.log('value', e.target.value);
            setChipData(prev => [...prev, e.target.value]);
            e.target.value = "";
        }
    };

    return (
        <>
            <Grid item xs={11}>
                <FormControl required={true} sx={{
                    marginTop: 2,
                }}>
                    <InputLabel htmlFor="name">Pregunta {position+1}.</InputLabel>
                    <Input id="name" name="name" value={questions[position].name} onChange={changeName}
                        sx={{ width: '280px' }} />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl required={true} sx={{
                    marginTop: 2,
                }}>
                    <InputLabel htmlFor="type">Tipo</InputLabel>
                    <Select
                        labelId="type"
                        id="type"
                        value={questions[position].type}
                        label="type"
                        onChange={changeType}
                        sx={{ width: '280px' }}
                    >
                        <MenuItem value="question">Pregunta abierta</MenuItem>
                        <MenuItem value="select">Pregunta cerrada</MenuItem>
                        <MenuItem value="multipleSelect">Opciones múltiples</MenuItem>
                        <MenuItem value="numeric">Valor numérico</MenuItem>
                        <MenuItem value="date">Fecha</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Button color="error" onClick={deleteQuestion}>Borrar pregunta {position+1}</Button>
            </Grid>
            {["select","multipleSelect"].includes(questions[position].type) ? <Grid item xs={12}>
                <FormControl required={true} sx={{
                    marginTop: 2,
                }}>
                    <InputLabel htmlFor="options">Opciones</InputLabel>
                    <Input id='options' name='options' onKeyDown={keyPress}
                        sx={{ width: '280px' }} />
                    {chipData.length > 0 && <ChipsArray chipData={chipData} setChipData={setChipData} />}
                </FormControl>
            </Grid>
                : null}
        </>
    );
}

export default ItemsModalQuestion;
