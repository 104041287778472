import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, Fab } from "@mui/material";
import UsersDataGrid from "./UsersDataGrid";
import AddIcon from "@mui/icons-material/Add";
import SnackbarAlert from "../utils/SnackbarAlert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import UsersModal from "./UsersModal";

const UsersLayout = ({ user }) => {
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [reloadUsers, setReloadUsers] = useState(0);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [notificationMessage, setNotificationMessage] = useState("");

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get("/api/allUsers");
        let usersResponse = response.data.response.map((obj, i) => {
          obj.id = obj.uid;
          obj.actions = i;

          return obj;
        });
        console.log(usersResponse);
        setUsers(usersResponse);
        setLoaded(true);
      } catch (error) {
        console.log(error);
        setLoaded(true);
      }
    };
    getUsers();
  }, [reloadUsers]);

  const handleOpen = (action, position) => {
    setModalType(action);
    setSelectedPosition(position);
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  return (
    <Box
      sx={{
        mt: 6,
        width: "100%",
      }}
    >
      {openModal && (
        <UsersModal
          user={user}
          openModal={openModal}
          handleClose={handleClose}
          position={selectedPosition}
          type={modalType}
          users={users}
          setReloadUsers={setReloadUsers}
          setOpen={setOpen}
          setSeverity={setSeverity}
          setNotificationMessage={setNotificationMessage}
        />
      )}
      {loaded ? (
        <Box>
          <Typography variant="h4"> Lista de usuarios </Typography>
          <UsersDataGrid users={users} handleOpen={handleOpen} />
        </Box>
      ) : (
        <Box>
          <Typography variant="body">Cargando...</Typography>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Fab
        onClick={() => handleOpen("add")}
        color="warning"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
        }}
      >
        <AddIcon />
      </Fab>
      <SnackbarAlert
        open={open}
        setOpen={setOpen}
        severity={severity}
        notificationMessage={notificationMessage}
      />
    </Box>
  );
};

export default UsersLayout;
