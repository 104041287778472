import { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HistoryIcon from '@mui/icons-material/History';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ArticleIcon from '@mui/icons-material/Article';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import PeopleIcon from "@mui/icons-material/People";
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { signOutGoogle } from '../utils/firebase-config';
import '../app.scss';
import NewChecklistLayout from './new checklists/NewChecklistLayout';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import NotFound from './NotFound';
import EditChecklistLayout from './edit checklist/EditChecklistLayout';
import ChecklistLayout from './checklists/ChecklistLayout';
import SelectChecklistLayout from './select checklist/SelectChecklistLayout';
import FillChecklistLayout from './fill checklist/FillChecklistLayout';
import SelectHistoricChecklistLayout from './check answer/SelectHistoricChecklistLayout';
import SelectChecklistsAnswerLayout from './check answer/SelectChecklistAnswerLayout';
import AnswerLayout from './check answer/AnswerLayout';
import AreasLayout from './items/AreasLayout';
import ItemsLayout from './items/ItemsLayout';
import UsersLayout from './users/UsersLayout';
import StoresLayout from './stores/StoresLayout';

const drawerWidth = 240;

function Main(props) {
    const location = useLocation();
    const navigate = useNavigate()
    const { window, user } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    const renderSwitch = (index) => {
        if(user.role==="admin"){
            switch (index) {
                case 0:
                    return <AreasLayout user={user}/>
                case 1:
                    return <NewChecklistLayout user={user}/>
                case 2:
                    return <ChecklistLayout />
                case 3:
                    return <SelectChecklistLayout user={user}/>
                case 4:
                    return <SelectHistoricChecklistLayout user={user}/>
                case 5:
                    return <UsersLayout user={user} />    
                case 6:
                    return <StoresLayout user={user} />    
                default:
                    return null;
            }
        } else {
            switch (index) {
                case 0:
                    return <SelectChecklistLayout user={user}/>
                case 1:
                    return <SelectHistoricChecklistLayout user={user}/>
                default:
                    return null;
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navigateToRoot = () => {
        if (location.pathname !== '/') {
            navigate(`/`)
        }
    }

    const drawerArray = user.role === "admin" ? ['Items', 'Nuevo checklist', 'Mis checklists', 'Realizar checklist', 'Historial checklists', 'Usuarios', 'Sucursales'] : ['Realizar checklist', 'Historial checklists']

    const drawer = (
        <div>
            <Toolbar sx={{ background: 'black' }} />
            <Divider />
            <List>
                {drawerArray.map((text, index) => (
                    <ListItem key={text} disablePadding onClick={() => {
                        setSelectedIndex(index);
                        navigateToRoot();
                    }}
                        sx={{ bgcolor: 'white', ...(index === selectedIndex && { bgcolor: '#E6D41E' }) }}>
                        <ListItemButton>
                            <ListItemIcon>
                                {user.role ==="admin" ? (index === 1 ? <AddBoxIcon /> : index === 2 ? <ArticleIcon /> : index === 3 ? <FactCheckIcon /> : index === 4 ? <HistoryIcon /> : index === 5 ? <PeopleIcon /> : <VideoLabelIcon />):
                                index === 0 ? <FactCheckIcon /> : <HistoryIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    background: 'black'
                }}
            >
                <Toolbar>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h6" noWrap component="div">
                                Checklist Liquidos.cl
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Avatar
                                aaria-describedby={id}
                                onClick={handleClick}
                                sx={{ cursor: 'pointer' }}
                                src={user.photoURL} />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                sx={{ marginTop: 1 }}
                            >
                                <Button variant='contained' onClick={signOutGoogle}>Cerrar sesión</Button>
                            </Popover>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderColor: 'black' },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderColor: 'black' },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {user.role === "admin" ? <Routes>
                    <Route path="/" element={renderSwitch(selectedIndex)} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/areas/:areaId" element={<ItemsLayout setSelectedIndex={setSelectedIndex} user={user}/>} />
                    <Route path="/checklist/:checklistId" element={<EditChecklistLayout setSelectedIndex={setSelectedIndex} user={user}/>} />
                    <Route path="/fillchecklist/:checklistId" element={<FillChecklistLayout setSelectedIndex={setSelectedIndex} user={user} role="admin"/>} />
                    <Route path="/answers/:checklistId" element={<SelectChecklistsAnswerLayout setSelectedIndex={setSelectedIndex} role="admin"/>} />
                    <Route path="/answers/:checklistId/:answerId" element={<AnswerLayout setSelectedIndex={setSelectedIndex} role="admin"/>} />
                </Routes> : <Routes>
                    <Route path="/" element={renderSwitch(selectedIndex)} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/fillchecklist/:checklistId" element={<FillChecklistLayout setSelectedIndex={setSelectedIndex} user={user} role="user" />} />
                    <Route path="/answers/:checklistId" element={<SelectChecklistsAnswerLayout setSelectedIndex={setSelectedIndex} role="user" />} />
                    <Route path="/answers/:checklistId/:answerId" element={<AnswerLayout setSelectedIndex={setSelectedIndex} role="user" />} />
                </Routes>}
            </Box>
        </Box>
    );
}

Main.propTypes = {
    window: PropTypes.func,
};

export default Main;
