export function getLocation() {
  return new Promise((resolve, reject) => {
    // Verificar si el navegador soporta la geolocalización
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          var latitud = position.coords.latitude;
          var longitud = position.coords.longitude;
          // console.log("Latitud:", latitud);
          // console.log("Longitud:", longitud);
          resolve({ latitude: latitud, longitude: longitud });
        },
        function(error) {
          // Manejar errores
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("El usuario no permitió el acceso a su ubicación.");
              reject(new Error("Permission denied"));
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("La información de ubicación no está disponible.");
              reject(new Error("Position unavailable"));
              break;
            case error.TIMEOUT:
              console.log("Se agotó el tiempo para obtener la ubicación del usuario.");
              reject(new Error("Timeout"));
              break;
            default:
              console.log("Ocurrió un error desconocido.");
              reject(new Error("Unknown error"));
              break;
          }
        }
      );
    } else {
      console.log("Geolocalización no es soportada en este navegador.");
      reject(new Error("Geolocation is not supported"));
    }
  });
}
