import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Tooltip } from "@mui/material";

export default function UsersDataGrid({ users, loading, handleOpen }) {
  const columns = [
    { field: "id", flex: 2 },
    { field: "name", flex: 2 },
    { field: "email", flex: 1.5 },
    { field: "role", flex: 1 },
    { field: "created_at", flex: 2 },
    { field: "updated_at", flex: 2 },
    {
      field: "actions",
      flex: .5,
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <Tooltip title={"Delete user"}>
            <IconButton
              color="primary"
              onClick={() => handleOpen("delete", params.value)}
              disabled={
                users[params.value].role === "super-admin" ? true : false
              }
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <DataGrid
      slots={{
        loadingOverlay: LinearProgress,
      }}
      columns={columns}
      rows={users}
      disableRowSelectionOnClick
      sx={{ maxWidth: "90vw", m: 2 }}
    />
  );
}
