import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SnackbarAlert from "../utils/SnackbarAlert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import StoresDataGrid from "./StoresDataGrid";
import StoresModal from "./StoresModal";

const StoresLayout = ({ user }) => {
  const [loaded, setLoaded] = useState(false);
  const [stores, setStores] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [reloadStores, setReloadStores] = useState(0);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [notificationMessage, setNotificationMessage] = useState("");

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get("/api/store");
        let storesResponse = response.data.response.map((obj, i) => {
          obj.id = obj.uid;
          obj.actions = i;

          return obj;
        });
        console.log(storesResponse);
        setStores(storesResponse);
        setLoaded(true);
      } catch (error) {
        console.log(error);
        setLoaded(true);
      }
    };
    getUsers();
  }, [reloadStores]);

  const handleOpen = (action, position) => {
    setModalType(action);
    setSelectedPosition(position);
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  return (
    <Box
      sx={{
        mt: 6,
        width: "100%",
      }}
    >
      {openModal && (
        <StoresModal
          user={user}
          openModal={openModal}
          handleClose={handleClose}
          position={selectedPosition}
          type={modalType}
          stores={stores}
          setReloadStores={setReloadStores}
          setOpen={setOpen}
          setSeverity={setSeverity}
          setNotificationMessage={setNotificationMessage}
        />
      )}
      {loaded ? (
        <Box>
          <Typography variant="h4"> Lista de sucursales </Typography>
          <StoresDataGrid stores={stores} handleOpen={handleOpen} />
        </Box>
      ) : (
        <Box>
          <Typography variant="body">Cargando...</Typography>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Fab
        onClick={() => handleOpen("add")}
        color="warning"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
        }}
      >
        <AddIcon />
      </Fab>
      <SnackbarAlert
        open={open}
        setOpen={setOpen}
        severity={severity}
        notificationMessage={notificationMessage}
      />
    </Box>
  );
};

export default StoresLayout;
