import { useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typhography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import '../../scss/NewChecklistCard.scss'

const typeParser = (type) => {
    switch(type){
        case "question":
            return "Pregunta abierta"
        case "select":
            return "Pregunta cerrada"
        case "multipleSelect":
            return "Opciones múltiples"
        case "numeric":
            return "Valor numérico"
        case "date":
            return "Fecha"
        default:
            return null;           
    }
}

const ItemsCard = ({ items, handleOpen, position }) => {
    const [openDial, setOpenDial] = useState(false);

    const handleClick = () => { setOpenDial(!openDial); };

    const handleCliCkAway = () => {
        setOpenDial(false);
    }

    const actions = [
        { icon: <IconButton onClick={() => handleOpen("edit", position)}><EditIcon /></IconButton>, name: 'Edit' },
        { icon: <IconButton onClick={() => handleOpen("delete", position)}><DeleteIcon /></IconButton>, name: 'Delete' },
    ];

    //hacer un input al lado del typhograpy, donde vaya la position, que cuando se haga click en mover se pueda editar

    return (
        <Card elevation={12}>
            <CardHeader
                sx={{ position: "relative", p: 1, m: 1, borderBottom: 1, borderColor: 'grey.500', minHeight: 50, flexWrap: 'wrap' }}
                title={<Box><Typhography variant="h5" component="h3" >{items[position]?.name}</Typhography></Box>}
                action={
                    <ClickAwayListener onClickAway={handleCliCkAway}>
                        <SpeedDial
                            ariaLabel="SpeedDial"
                            icon={<SpeedDialIcon />}
                            direction="down"
                            FabProps={{ size: 'medium', border: 'none' }}
                            onClick={handleClick}
                            elevation={0}
                            open={openDial}
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={handleClick}
                                    delay={1}
                                />
                            ))}
                        </SpeedDial>
                    </ClickAwayListener>
                }>
            </CardHeader>
            <CardContent>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    {items[position].questions.map((question, i) => (
                        <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typhography variant="body">{i+1}. Pregunta: {question.name}</Typhography>
                            <Typhography variant="body">Tipo: {typeParser(question.type)}</Typhography>
                            {question.options?  <><Typhography variant='body' component={'p'}>Opciones:</Typhography>
                        {question.options.map((obj, i) => (<Typhography key={"opt"+i}>{obj}</Typhography>))}</> : null}
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card >
    );
};

export default ItemsCard;