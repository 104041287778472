import "../../scss/NewChecklistLayout.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SnackbarAlert from "../utils/SnackbarAlert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import EditChecklistModal from "./EditChecklistModal";
import EditChecklistItemCard from "./EditChecklistItemCard";
import SaveIcon from "@mui/icons-material/Save";
import EditChecklistSaveModal from "./EditChecklistSaveModal";
import { useParams } from "react-router-dom";

const theme = createTheme({
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important`,
  },
});

function EditChecklistLayout({ user, setSelectedIndex }) {
  const [checklist, setChecklist] = useState({ name: null, items: [] });
  const [areas, setAreas] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [notificationMessage, setNotificationMessage] = useState("");

  const { checklistId } = useParams();

  useEffect(() => {
    setSelectedIndex(2);

    const getAreas = async () => {
      try {
        const response = await axios.get(`/api/areasItems`);
        setAreas(response.data.response);
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    };

    const getChecklist = async () => {
      try {
        const response = await axios.get(`/api/checklist/${checklistId}`);
        setChecklist(response.data.response);
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    };

    const loadData = async () => {
      await Promise.all([getAreas(), getChecklist()]);
      setLoaded(true);
    };

    loadData();
  }, [checklistId, setSelectedIndex]);

  const handleOpen = (action, position) => {
    setModalType(action);
    setSelectedPosition(position);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  const handleSaveOpen = () => {
    setOpenSaveModal(true);
  };
  const handleSaveClose = () => setOpenSaveModal(false);

  const saveChecklist = async () => {
    handleSaveOpen();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {openModal && (
          <EditChecklistModal
            checklist={checklist}
            setChecklist={setChecklist}
            areas={areas}
            openModal={openModal}
            handleClose={handleClose}
            type={modalType}
            position={selectedPosition}
          />
        )}
        {loaded ? (
          <Box>
            <Paper
              sx={{
                p: 1.5,
                mb: 1,
                backgroundColor: "#E6D41E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{ color: "black", ml: 4 }}
              >
                Editar checklist
              </Typography>
            </Paper>
            <Container
              maxWidth="xl"
              sx={{ mt: 2, mb: 4, display: "flex", flexWrap: "wrap" }}
            >
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 3, sm: 6, md: 12 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h4" component="h2">
                    Agrega preguntas al checklist
                  </Typography>
                </Grid>
                {checklist.items.length > 0 ? (
                  checklist.items.map((item, i) => (
                    <Grid key={i} item xs={12}>
                      <EditChecklistItemCard
                        key={"p" + i}
                        item={item}
                        handleOpen={handleOpen}
                        position={i}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" component="h4">
                      No tienes preguntas agregadas, haz click en el botón +
                      para agregar
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Fab
                onClick={() => handleOpen("add")}
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 32,
                  right: 64,
                  background: "#E6D41E",
                }}
              >
                <AddIcon />
              </Fab>
              {checklist.items.length > 0 && (
                <Fab
                  onClick={saveChecklist}
                  aria-label="add"
                  sx={{
                    position: "fixed",
                    bottom: 32,
                    right: `calc(100% - ${240 + 64 + 48}px)`,
                    background: "#E6D41E",
                  }}
                >
                  <SaveIcon />
                </Fab>
              )}
            </Container>
          </Box>
        ) : (
          <Box>
            <Typography variant="body">Cargando...</Typography>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <SnackbarAlert
          open={open}
          setOpen={setOpen}
          severity={severity}
          notificationMessage={notificationMessage}
        />
        {openSaveModal && (
          <EditChecklistSaveModal
            openSaveModal={openSaveModal}
            handleSaveClose={handleSaveClose}
            user={user}
            checklist={checklist}
            setOpen={setOpen}
            setSeverity={setSeverity}
            setNotificationMessage={setNotificationMessage}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default EditChecklistLayout;
