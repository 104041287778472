import '../../scss/NewChecklistLayout.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SnackbarAlert from '../utils/SnackbarAlert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import NewChecklistModal from './NewChecklistModal';
import NewChecklistItemCard from './NewChecklistItemCard';
import SaveIcon from '@mui/icons-material/Save';
import NewChecklistSaveModal from './NewChecklistSaveModal';

const theme = createTheme({
    typography: {
        "fontFamily": `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important`,
    }
});

function NewChecklistLayout({ user }) {
    const [checklist, setChecklist] = useState({ name: null, items: []});
    const [areas, setAreas] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState("add");
    const [selectedPosition, setSelectedPosition] = useState(0);
    const [openSaveModal, setOpenSaveModal] = useState(false);

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [notificationMessage, setNotificationMessage] = useState('');

    useEffect(() => {
        const getAreas = async () => {
          const response = await axios.get(`/api/areasItems`);
          console.log(response.data.response);
          setAreas(response.data.response)
        };
    
        getAreas();
      }, []);

    const handleOpen = (action, position) => {
        setModalType(action);
        setSelectedPosition(position);
        setOpenModal(true);
    }
    const handleClose = () => setOpenModal(false);

    const handleSaveOpen = () => {
        setOpenSaveModal(true);
    }
    const handleSaveClose = () => setOpenSaveModal(false);

    const saveChecklist = async () => {
        handleSaveOpen();
    }

    return (
        <ThemeProvider theme={theme}>
            <Box>
                {openModal && <NewChecklistModal checklist={checklist} setChecklist={setChecklist} areas={areas} openModal={openModal} handleClose={handleClose} type={modalType} position={selectedPosition} />}
                <Box>
                    <Paper sx={{
                        p: 1.5, mb: 1, backgroundColor: '#E6D41E', display: 'flex', justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography variant='h5' component='h5' sx={{ color: 'black', ml: 4 }}>Nuevo checklist</Typography>
                    </Paper>
                    <Container maxWidth='xl' sx={{ mt: 2, mb: 4, display: 'flex', flexWrap: 'wrap' }} >
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3, sm: 6, md: 12 }} >
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h4' component='h2'>Agrega preguntas al checklist</Typography>
                            </Grid>
                            {checklist.items.length > 0 ? checklist.items.map((item, i) => (
                                <Grid key={i} item xs={12}>
                                    <NewChecklistItemCard key={'p' + i} item={item} handleOpen={handleOpen} position={i} />
                                </Grid>
                            )) : <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h6' component='h4'>No tienes preguntas agregadas, haz click en el botón + para agregar</Typography>
                            </Grid>}
                        </Grid>
                        <Fab onClick={() => handleOpen("add")} aria-label="add" sx={{
                            position: 'fixed',
                            bottom: 32,
                            right: 64,
                            background: '#E6D41E'
                        }}>
                            <AddIcon />
                        </Fab>
                        {checklist.items.length > 0 && <Fab onClick={saveChecklist} aria-label="add" sx={{
                            position: 'fixed',
                            bottom: 32,
                            right: `calc(100% - ${240 + 64 + 48}px)`,
                            background: '#E6D41E'
                        }}>
                            <SaveIcon />
                        </Fab>}
                    </Container>
                </Box>
                <SnackbarAlert open={open} setOpen={setOpen} severity={severity} notificationMessage={notificationMessage} />
                {openSaveModal && <NewChecklistSaveModal openSaveModal={openSaveModal} handleSaveClose={handleSaveClose} user={user}
                    checklist={checklist} setOpen={setOpen} setSeverity={setSeverity} setNotificationMessage={setNotificationMessage} />}
            </Box>
        </ThemeProvider>
    );
}

export default NewChecklistLayout;