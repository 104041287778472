import '../../scss/NewChecklistLayout.scss';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import SelectChecklistCard from './SelectHistoricChecklistCard';
import SnackbarAlert from '../utils/SnackbarAlert';

const theme = createTheme({
    typography: {
        "fontFamily": `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important`,
    }
});

const SelectHistoricChecklistLayout = () => {
    const [checklists, setChecklists] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [notificationMessage, setNotificationMessage] = useState('');

    useEffect(() => {
        const getChecklists = async () => {
            try {
                const response = await axios.get(`/api/checklist`);
                console.log(response.data.response);
                setChecklists(response.data.response);
                setOpenBackdrop(false);
                setLoaded(true);
            } catch (err) {
                console.log(err);
                setOpenBackdrop(false);
                setLoaded(true);
                setError(true);
                setOpen(true)
                setSeverity("error")
                setNotificationMessage("Error obteniendo checklists")
            }
        }
        getChecklists();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {(loaded & !error) ? <Box>
                    <Paper sx={{
                        p: 1.5, mb: 1, backgroundColor: '#E6D41E', display: 'flex', justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography variant='h5' component='h5' sx={{ color: 'black', ml: 4 }}>Mis checklist</Typography>
                    </Paper>
                    <Container maxWidth='xl' sx={{ mt: 2, mb: 4, display: 'flex', flexWrap: 'wrap' }} >
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3, sm: 6, md: 12 }} >
                            {checklists.length > 0 ? checklists.map((_checklist, i) => (
                                <Grid key={i} item xs={12}>
                                    <SelectChecklistCard key={'c' + i} checklists={checklists} position={i} />
                                </Grid>
                            )) : <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h6' component='h4'>No tienes checklist agregados, anda a la sección Nuevo checklist para agregar</Typography>
                            </Grid>}
                        </Grid>
                    </Container>
                </Box> : (loaded & error) ? <Grid item xs={12} sm={12} md={12}>
                    <Typography variant='h6' component='h4'>Ha ocurrido un error, intenta de nuevo por favor</Typography>
                </Grid> : "Cargando..."}
                <SnackbarAlert open={open} setOpen={setOpen} severity={severity} notificationMessage={notificationMessage} />
            </Box>
        </ThemeProvider>
    );
}

export default SelectHistoricChecklistLayout;
