import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const EditChecklistModal = ({
  checklist,
  setChecklist,
  areas,
  openModal,
  handleClose,
  type,
  position,
}) => {
  const [area, setArea] = useState(null);
  const [item, setItem] = useState(null);
  const [positionValue, setPositionValue] = useState(
    position + 1 || checklist.items.length
  );
  const [photoRequired, setPhotoRequired] = useState(false);

  useEffect(() => {
    if (type === "add") {
      if (areas.length > 0) {
        setArea({ ...areas[0] });
        if (areas[0].items) {
          setItem({ ...areas[0].items[0] });
        }
      }
    } else {
      console.log(checklist.items[position]);
      const filteredArea = areas.filter(
        (area) => area.uid === checklist.items[position].areaId
      )[0];
      setArea(filteredArea);
      setItem(
        filteredArea.items.filter(
          (item) => item.uid === checklist.items[position].uid
        )[0]
      );
      setPhotoRequired(checklist.items[position].photoRequired);
    }
  }, [checklist, areas, position, type]);

  const addItem = (e) => {
    e.preventDefault();
    setChecklist({
      ...checklist,
      items: [...checklist.items, { ...item, photoRequired: photoRequired }],
    });
    closeModal();
  };

  const editItem = (e) => {
    e.preventDefault();

    const updatedItems = [...checklist.items];

    updatedItems[position] = {
      ...updatedItems[position],
      ...item,
      photoRequired: photoRequired,
    };

    setChecklist({
      ...checklist,
      items: updatedItems,
    });

    if (positionValue !== position + 1) {
      moveObject(position, positionValue - 1);
    }

    closeModal();
  };

  const deleteItem = (e) => {
    e.preventDefault();
    const newItems = [...checklist.items];
    newItems.splice(position, 1);
    setChecklist((prevChecklist) => ({
      ...prevChecklist,
      items: newItems,
    }));
    closeModal();
  };

  const closeModal = () => {
    handleClose();
  };

  const positionChange = (e) => {
    let pos = parseInt(e.target.value.replace(/([^\d]*)|(\D+)/g, "$1"));
    if (pos > 0 && pos <= checklist.items.length) {
      setPositionValue(pos);
    } else {
      if (isNaN(pos)) {
        setPositionValue("");
      }
    }
  };

  const moveObject = (fromIndex, toIndex) => {
    const newArray = [...checklist.items];

    const [removed] = newArray.splice(fromIndex, 1);

    newArray.splice(toIndex, 0, removed);
    console.log(newArray);

    setChecklist((prevChecklist) => ({
      ...prevChecklist,
      items: newArray,
    }));
  };

  const areaSelectChange = (e) => {
    const selectedUid = e.target.value;

    const selectedArea = areas.find((area) => area.uid === selectedUid);
    if (selectedArea) {
      setArea(selectedArea);

      if (selectedArea.items && selectedArea.items.length > 0) {
        setItem(selectedArea.items[0]);
      } else {
        setItem(null);
      }
    }
  };

  const itemSelectChange = (e) => {
    const selectedUid = e.target.value;

    const selectedItem = area.items.find((item) => item.uid === selectedUid);
    if (selectedItem) {
      setItem(selectedItem);
    }
  };

  return (
    <Modal
      disableScrollLock={true}
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          paddingBottom: 2,
        }}
      >
        <Container maxWidth="xl">
          {type !== "delete" ? (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  {type === "add" ? "Nuevo item" : "Modificar item"}
                </Typography>
              </Grid>
              {areas.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <FormControl
                      required={true}
                      sx={{
                        marginTop: 2,
                      }}
                    >
                      <InputLabel htmlFor="type">Area</InputLabel>
                      <Select
                        value={area?.uid || ""}
                        label="type"
                        onChange={areaSelectChange}
                        sx={{ width: "280px" }}
                      >
                        {areas.map((area, i) => (
                          <MenuItem key={"area" + i} value={area.uid}>
                            {area.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      required={true}
                      sx={{
                        marginTop: 2,
                      }}
                    >
                      <InputLabel htmlFor="type">Item</InputLabel>
                      <Select
                        value={item?.uid || ""}
                        label="type"
                        onChange={itemSelectChange}
                        sx={{ width: "280px" }}
                      >
                        {area?.items?.map((item, i) => (
                          <MenuItem key={"item" + i} value={item.uid}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={photoRequired}
                          onChange={(e) => setPhotoRequired(!photoRequired)}
                        />
                      }
                      label="Foto obligatoria"
                    />
                  </Grid>
                </>
              ) : null}
              {type === "edit" ? (
                <>
                  <Grid item xs={12}>
                    <FormControl
                      required={true}
                      sx={{
                        marginTop: 2,
                      }}
                    >
                      <InputLabel htmlFor="position">Posición</InputLabel>
                      <Input
                        id="position"
                        name="position"
                        type="number"
                        inputProps={{
                          pattern: "[0-9]*",
                          min: 1,
                          max: checklist.items.length,
                        }}
                        sx={{ width: "280px" }}
                        value={positionValue}
                        onChange={positionChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      onClick={editItem}
                      sx={{
                        alignSelf: "flex-end",
                        mt: 0.2,
                        mb: 0,
                        borderRadius: 0,
                        boxShadow: "none",
                      }}
                    >
                      Editar item
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <LoadingButton
                    size="large"
                    variant="contained"
                    onClick={addItem}
                    sx={{
                      alignSelf: "flex-end",
                      mt: 0.2,
                      mb: 0,
                      borderRadius: 0,
                      boxShadow: "none",
                    }}
                  >
                    Agregar item
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  Seguro que quieres eliminar el checklist {checklist.name}?
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{
                    alignSelf: "flex-end",
                    mt: 0.2,
                    mb: 0,
                    borderRadius: 0,
                    boxShadow: "none",
                    width: "75%",
                  }}
                  onClick={closeModal}
                >
                  No
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  sx={[
                    {
                      alignSelf: "flex-end",
                      mt: 0.2,
                      mb: 0,
                      borderRadius: 0,
                      boxShadow: "none",
                      backgroundColor: "red",
                      width: "75%",
                    },
                    (theme) => ({
                      "&:hover": {
                        backgroundColor: "#b42f2f",
                      },
                    }),
                  ]}
                  onClick={deleteItem}
                >
                  Sí
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </Modal>
  );
};

export default EditChecklistModal;
