import { Grid, IconButton } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";

const FillChecklistPhotoLayout = ({
  item,
  setType,
  handleOpen,
  position,
  photoPosition,
  setChecklist,
  checklist,
}) => {

  const deletePhoto = () => {
    const updatedChecklist = { ...checklist };

    if(updatedChecklist.items[position].photo.length===1){
        delete updatedChecklist.items[position].photo
    }else {
        updatedChecklist.items[position].photo.splice(photoPosition, 1);
    }
    setChecklist(updatedChecklist);
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={2}>
        <IconButton>
          <AddAPhotoIcon
            onClick={() => {
              setType("take");
              handleOpen(photoPosition);
            }}
          />
        </IconButton>
      </Grid>
      <Grid item xs={8}>
        {item.photo?.[photoPosition] && (
          <img
            src={URL.createObjectURL(item.photo[photoPosition])}
            alt="liquidos.cl"
            style={{ width: "60%", height: "100%", cursor: "pointer" }}
            onClick={() => {
              setType("get");
              handleOpen(photoPosition);
            }}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {item.photo?.[photoPosition] ? (
          <IconButton>
            <DeleteIcon onClick={deletePhoto} />
          </IconButton>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default FillChecklistPhotoLayout;
