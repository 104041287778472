import { useRef, useState, useEffect, Fragment } from "react";
import { IconButton, Button } from "@mui/material";
import CameraIcon from "@mui/icons-material/Camera";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CancelIcon from "@mui/icons-material/Cancel";

function Camera({
  checklist,
  setChecklist,
  position,
  type,
  setType,
  handleClose,
  selectedPhotoPosition,
}) {
  const videoRef = useRef(null);
  // const [photo, setPhoto] = useState(null);
  const [facingMode, setFacingMode] = useState("environment"); // default to front camera
  const [stream, setStream] = useState(null);

  useEffect(() => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }

    const constraints = {
      video: {
        facingMode: facingMode,
        width: { ideal: 1920 },
        height: { ideal: 1080 },
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {console.error("Something went wrong!", error)});
  }, [facingMode, type]);

  useEffect(() => {
    return () => {
      if (checklist.items[position] && checklist.items[position].photoURL) {
        URL.revokeObjectURL(checklist.items[position].photoURL);
      }
    };
  }, []);

  useEffect(() => {  
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  const handlePhoto = (photo) => {
    const updatedChecklist = { ...checklist };

    if (updatedChecklist.items[position].photo) {
      updatedChecklist.items[position].photo[selectedPhotoPosition] = photo;
    } else {
      updatedChecklist.items[position].photo = [photo];
    }
    setChecklist(updatedChecklist);
  };

  const captureUsingCanvas = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas
      .getContext("2d")
      .drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      handlePhoto(blob);
      setType("get");
    }, "image/jpeg");
  };

  const takeSnapshot = async () => {
    // const track = stream.getVideoTracks()[0];
    // const imageCapture = new ImageCapture(track);
    // const photoBlob = await imageCapture.takePhoto();
    // handlePhoto(photoBlob);
    // setType("get");
    captureUsingCanvas();
  };

  const newPhoto = () => {
    setType("take");
  };

  const toggleCamera = () => {
    setFacingMode((prevState) =>
      prevState === "user" ? "environment" : "user"
    );
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {type === "take" ? (
        <Fragment>
          <video
            ref={videoRef}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            autoPlay
            playsInline
          />
          <IconButton
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
            }}
          >
            <CancelIcon />
          </IconButton>
          <IconButton
            onClick={takeSnapshot}
            style={
              window.innerHeight > window.innerWidth
                ? {
                    position: "absolute",
                    left: "10px",
                    bottom: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }
                : {
                    position: "absolute",
                    left: "10px",
                    bottom: "50%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }
            }
          >
            <CameraIcon />
          </IconButton>
          <IconButton
            onClick={toggleCamera}
            style={
              window.innerHeight > window.innerWidth
                ? {
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }
                : {
                    position: "absolute",
                    right: "10px",
                    bottom: "50%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }
            }
          >
            <CameraswitchIcon />
          </IconButton>
        </Fragment>
      ) : (
        <Fragment>
          <img
            src={checklist.items[position].photo[selectedPhotoPosition] instanceof Blob ? URL.createObjectURL(checklist.items[position].photo[selectedPhotoPosition]) : null}
            style={{
              display: "block",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="liquidos.cl"
          />
          <IconButton
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
            }}
          >
            <CancelIcon />
          </IconButton>
          <Button
            onClick={newPhoto}
            style={{
              position: "absolute",
              left: "10px",
              bottom: "10px",
            }}
          >
            Tomar otra
          </Button>
        </Fragment>
      )}
    </div>
  );
}

export default Camera;
