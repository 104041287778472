import "../../scss/NewChecklistLayout.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import SnackbarAlert from "../utils/SnackbarAlert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ItemsModal from "./ItemsModal";
import ItemsCard from "./ItemsCard";

const theme = createTheme({
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important`,
  },
});

function ItemsLayout({ user, setSelectedIndex }) {
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [refresher, setRefresher] = useState(0);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [notificationMessage, setNotificationMessage] = useState("");

  const { areaId } = useParams();

  useEffect(() => {
    setSelectedIndex(0);

    const getItems = async () => {
      try {
        const response = await axios.get(`/api/areas/${areaId}`);
        console.log(response.data.response.items);
        setItems(response.data.response.items);
        setLoaded(true);
      } catch (err) {
        console.log(err);
        setLoaded(true);
        setOpen(true)
        setSeverity("error")
        setNotificationMessage("Error cargando items")
      }
    };

    getItems();
  }, [areaId, refresher, setSelectedIndex]);

  const updateRefresher = () => {
    setRefresher((prev) => prev + 1);
  };

  const handleOpen = (action, position) => {
    setModalType(action);
    setSelectedPosition(position);
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {openModal && (
          <ItemsModal
            user={user}
            items={items}
            openModal={openModal}
            handleClose={handleClose}
            type={modalType}
            position={selectedPosition}
            areaId={areaId}
            updateRefresher={updateRefresher}
          />
        )}
        {loaded ? (
          <Box>
            <Paper
              sx={{
                p: 1.5,
                mb: 1,
                backgroundColor: "#E6D41E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{ color: "black", ml: 4 }}
              >
                Items
              </Typography>
            </Paper>
            <Container
              maxWidth="xl"
              sx={{ mt: 2, mb: 4, display: "flex", flexWrap: "wrap" }}
            >
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 3, sm: 6, md: 12 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h4" component="h2">
                    Agrega items con preguntas
                  </Typography>
                </Grid>
                {items.length > 0 ? (
                  items.map((item, i) => (
                    <Grid key={i} item xs={12}>
                      <ItemsCard
                        key={"p" + i}
                        items={items}
                        handleOpen={handleOpen}
                        position={i}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" component="h4">
                      No tienes items agregados, haz click en el botón + para
                      agregar
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Fab
                onClick={() => handleOpen("add")}
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 32,
                  right: 64,
                  background: "#E6D41E",
                }}
              >
                <AddIcon />
              </Fab>
            </Container>
          </Box>
        ) : (
          <Box>
            <Typography variant="body">Cargando...</Typography>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <SnackbarAlert
          open={open}
          setOpen={setOpen}
          severity={severity}
          notificationMessage={notificationMessage}
        />
      </Box>
    </ThemeProvider>
  );
}

export default ItemsLayout;
