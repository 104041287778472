import "../../scss/NewChecklistLayout.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SnackbarAlert from "../utils/SnackbarAlert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FillChecklistItemCard from "./FillChecklistItemCard";
import SaveIcon from "@mui/icons-material/Save";
import FillChecklistSaveModal from "./FillChecklistSaveModal";
import { useParams } from "react-router-dom";
import { FormControl, MenuItem, Select, FormHelperText } from "@mui/material";

import { getLocation } from "../../utils/location";

const theme = createTheme({
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important`,
  },
});

const getCurrentDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Enero es 0!
  const yyyy = today.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

const areArraysEqual = (array1, array2) => JSON.stringify(array1) === JSON.stringify(array2);

const defaultAnswers = {
  question: "",
  select: "",
  multipleSelect: [],
  numeric: 0,
  date: getCurrentDate(),
};

function FillChecklistLayout({ user, role, setSelectedIndex }) {
  const [checklist, setChecklist] = useState({ name: null, items: [] });
  const [loaded, setLoaded] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(null);
  const [location, setLocation] = useState({});
  const [autosaveItems, setAutosaveItems] = useState(null)
  const [autosaveLoaded, setAutosaveLoaded] = useState(false)

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [notificationMessage, setNotificationMessage] = useState("");

  const { checklistId } = useParams();

  useEffect(() => {
    if (role === "admin") {
      setSelectedIndex(3);
    } else {
      setSelectedIndex(1);
    }

    getLocation()
      .then((location) => {
        setLocation(location);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [role, setSelectedIndex]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axios.get(`/api/store`);
        setStores(response.data.response);
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    };

    const getChecklist = async () => {
      try {
        const response = await axios.get(`/api/checklist/${checklistId}`);
        const data = response.data.response;
        data.items.forEach((item) => {
          item.questions.forEach((question) => {
            question.answer = defaultAnswers[question.type];
          });
        });
        setChecklist(data);
        return data
      } catch (err) {
        console.log(err);
        return false;
      }
    };

    const getAutosave = async (checklistData) => {
      try {
        const response = await axios.get(`/api/autosave/${checklistId}/${user.uid}`);
        const data = response.data.response;
        
        if(data.length === 0) {
          const body = {
            userId: user.uid,
            location,
            storeId: store,
            items: checklistData.items
          }
          
          await axios.post(`/api/autosave/${checklistId}`, body, {
            headers: {
              "content-type": "application/json",
            },
          });
        } else {
          // setChecklist({...checklist, items: JSON.parse(JSON.stringify(data[0].items)), storeId: data[0].storeId})
          setChecklist(prev => ({...prev, items: JSON.parse(JSON.stringify(data[0].items)), storeId: data[0].storeId}))
          setStore(data[0].storeId)
        }
        setAutosaveLoaded(true)
      } catch (err) {
        console.log(err);
      }
    }

    const loadData = async () => {
      await Promise.all([getStores(), getChecklist()]).then(([_storesResponse, checklistResponse]) => {
        getAutosave(checklistResponse)
      });
      setLoaded(true);
    };

    if(checklistId) {
      loadData();
    }
  }, [checklistId]);

  // useEffect(() => {
  //   const getAutosave = async () => {
  //     try {
  //       const response = await axios.get(`/api/autosave/${checklistId}/${user.uid}`);
  //       const data = response.data.response;
        
  //       if(data.length === 0) {
  //         const body = {
  //           userId: user.uid,
  //           location,
  //           storeId: store
  //         }
          
  //         const response2 = await axios.post(`/api/autosave/${checklistId}`, body, {
  //           headers: {
  //             "content-type": "application/json",
  //           },
  //         });
  //         console.log(response2)
  //         setAutosaveId(response2.data.response.id)
  //       } else {
  //         console.log(data)
  //         console.log(data[0].items);
  //         setAutosaveId(data[0].uid)
  //         setChecklist({...checklist, items: JSON.parse(JSON.stringify(data[0].items)), storeId: data[0].storeId})
  //         setStore(data[0].storeId)
  //       }
  //       setAutosaveLoaded(true)
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }

  //   getAutosave()
  // }, [checklistId]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if(user.uid && autosaveLoaded && checklist?.items?.length > 0) {
        if(!areArraysEqual(autosaveItems, checklist.items)){
          try {
            const body = {
              items: checklist.items,
              location,
              storeId: store
            }
    
            await axios.put(`/api/autosave/${checklistId}/${user.uid}`, body, {
              headers: {
                "content-type": "application/json",
              },
            });
            setAutosaveItems(JSON.parse(JSON.stringify(checklist.items)));
          } catch(err) {
            console.log(err)
          }
        }
      } else{
        console.log("sin autosaveid o autosave cargado")
      }
      
    }, 5000);

    return () => clearInterval(intervalId);
  }, [store, checklist, location, autosaveItems, autosaveLoaded]);

  const handleSaveOpen = () => {
    setOpenSaveModal(true);
  };
  const handleSaveClose = () => setOpenSaveModal(false);

  const saveChecklist = async (e) => {
    e.preventDefault()
    if (store) {
      let formOk = true;
      let notOkMessage = "Debe rellenar las fotos de los item: "
      checklist.items.forEach((item, i) => {
        if (item.photoRequired && !item.photo) {
          formOk = false;
          notOkMessage+= `${i+1} `
        }
      });
      if (formOk) {
        handleSaveOpen();
      } else {
        setOpen(true);
        setSeverity("warning");
        setNotificationMessage(notOkMessage);
      }
    } else {
      setOpen(true);
      setSeverity("warning");
      setNotificationMessage("Debe seleccionar la tienda");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {loaded ? (
          <Box>
            <Paper
              sx={{
                p: 1,
                mb: 1,
                mt: 1,
                backgroundColor: "#E6D41E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{ color: "black", m: 1 }}
              >
                {checklist.name}
              </Typography>
            </Paper>
            <Container
              maxWidth="xl"
              sx={{ mt: 2, mb: 4, display: "flex", flexWrap: "wrap" }}
            >
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 3, sm: 6, md: 12 }}
                component="form"
                onSubmit={saveChecklist}
              >
                <Grid item xs={12} sx={{ marginBop: "20px" }}>
                  <FormControl fullWidth>
                    <Select
                      required
                      value={store || ""}
                      onChange={(e) => setStore(e.target.value)}
                    >
                      {stores?.length > 0 &&
                        stores.map((store, i) => (
                          <MenuItem key={"mi" + i} value={store.uid}>
                            {store.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      Selecciona la sucursal correspondiente
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h4" component="h2">
                    Responde el checklist
                  </Typography>
                </Grid>
                {checklist.items.length > 0 ? (
                  checklist.items.map((item, i) => (
                    <Grid key={i} item xs={12}>
                      <FillChecklistItemCard
                        key={"p" + i}
                        checklist={checklist}
                        setChecklist={setChecklist}
                        item={item}
                        position={i}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" component="h4">
                      Checklist no tiene items agregados
                    </Typography>
                  </Grid>
                )}
                {checklist.items.length > 0 && (
                <Fab
                  // onClick={saveChecklist}
                  type="submit"
                  aria-label="add"
                  sx={{
                    position: "fixed",
                    bottom: 32,
                    right: `calc(100% - ${240 + 64 + 48}px)`,
                    background: "#E6D41E",
                  }}
                >
                  <SaveIcon />
                </Fab>
              )}
              </Grid>
            </Container>
          </Box>
        ) : (
          <Box>
            <Typography variant="body">Cargando...</Typography>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}
        <SnackbarAlert
          open={open}
          setOpen={setOpen}
          severity={severity}
          notificationMessage={notificationMessage}
        />
        {openSaveModal && (
          <FillChecklistSaveModal
            openSaveModal={openSaveModal}
            handleSaveClose={handleSaveClose}
            user={user}
            storeId={store}
            location={location}
            checklist={checklist}
            setOpen={setOpen}
            setSeverity={setSeverity}
            setNotificationMessage={setNotificationMessage}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default FillChecklistLayout;
