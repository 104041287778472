import { useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typhography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';

import '../../scss/NewChecklistCard.scss'

const AreasCard = ({ areas, position, handleOpen }) => {
    const [openDial, setOpenDial] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => { setOpenDial(!openDial); };

    const handleCliCkAway = () => {
        setOpenDial(false);
    }

    const nagivateToAreaItems = () => {
        navigate(`/areas/${areas[position].uid}`)
    }

    const actions = [
        { icon: <IconButton onClick={() => handleOpen('edit', position)}><EditIcon /></IconButton>, name: 'Edit' },
        { icon: <IconButton onClick={() => handleOpen('delete', position)}><DeleteIcon /></IconButton>, name: 'Delete' },
    ];

    return (
        <Card elevation={12}>
            <CardHeader
                sx={[{ position: "relative", p: 1, m: 1, borderBottom: 1, borderColor: 'grey.500', minHeight: 120, flexWrap: 'nowrap' }]}
                title={<Box onClick={nagivateToAreaItems} 
                    sx={[
                        (theme) => ({
                        '&:hover': {
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }
                        })
                    ]}>
                        <Typhography variant="h5" component="h3" >{areas[position].name}</Typhography></Box>}
                            action={
                                <ClickAwayListener onClickAway={handleCliCkAway}>
                                    <SpeedDial
                                        ariaLabel="SpeedDial"
                                        icon={<SpeedDialIcon />}
                                        direction="down"
                                        FabProps={{ size: 'medium', border: 'none' }}
                                        onClick={handleClick}
                                        elevation={0}
                                        open={openDial}
                                    >
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                onClick={handleClick}
                                                delay={1}
                                            />
                                        ))}
                                    </SpeedDial>
                                </ClickAwayListener>
                            }>
            </CardHeader>
            <CardContent>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typhography variant='body' component={'p'}>Usuario: {areas[position].userName}</Typhography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typhography variant='body' component={'p'}>Fecha creación: {areas[position].created_at}</Typhography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typhography variant='body' component={'p'}>Fecha actualización: {areas[position].updated_at}</Typhography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    );
};

export default AreasCard;